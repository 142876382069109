<template>
  <modal
    :show="showModal"
    modal-classes="modal-secondary elite-modal validate-delivery"
    size="lg"
  >
    <div class="text-center mb-3">
      <h2>{{ $t("PURCHASES_DELIVERIES.VALIDATE_DELIVERY") }}</h2>
    </div>
    <form class="" @submit.prevent="">
      <div
        class="row delivery-item"
        v-for="item in deliveryItemsModel"
        :key="item.id"
      >
        <div class="delivery-item-name">
          <span class="code text-bold h3">{{ item.code }}</span>
          <span class="name">{{ item.purchasesDeliverable.name }}</span>
          <span class="except text-muted">{{ item.excerpt }}</span>
        </div>
        <div class="delivery-item-quantity">
          <div class="delivery-item-quantity-expected">
            <base-input
              :label="`${$t('COMMON.EXPECTED_QUANTITY')} (*)`"
              :placeholder="$t('COMMON.EXPECTED_QUANTITY')"
              v-model="deliveryItemsModel[item.id].expected_quantity"
              type="number"
              step="1"
              input-classes="form-control-alternative"
              :disabled="true"
            >
            </base-input>
          </div>
          <div class="delivery-item-quantity-delivered">
            <base-input
              :label="`${$t('COMMON.DELIVERED_QUANTITY')} (*)`"
              :placeholder="$t('COMMON.DELIVERED_QUANTITY')"
              v-model="deliveryItemsModel[item.id].quantity"
              type="number"
              step="1"
              input-classes="form-control-alternative"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.quantity" />
          </div>
        </div>
      </div>
    </form>

    <template slot="footer">
      <button
        type="submit"
        class="btn add"
        @click="handleSubmit"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("PURCHASES_DELIVERIES.VALIDATE_DELIVERY") }}
      </button>

      <button
        type="button"
        class="btn close"
        @click="closePurchasesOrderDeliveryForm"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  components: {
    ValidationError,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["purchasesDelivery", "showModal"],

  data() {
    this.setDeliveryItemsModel();
    return {
      deliveryItemsModel: [],
      loading: false,
    };
  },

  computed: {},

  methods: {
    setDeliveryItemsModel() {
      const deliveryItemsData = {};
      for (const item of this.purchasesDelivery.items) {
        deliveryItemsData[item.id] = cloneDeep(item);
      }
      this.deliveryItemsModel = deliveryItemsData;
    },

    closePurchasesOrderDeliveryForm() {
      this.$emit("closePurchasesOrderDeliveryForm");
    },

    async handleSubmit() {
      try {
        this.loading = true;

        for (const line of Object.values(this.deliveryItemsModel)) {
          const purchasesDeliveryItemData = _.cloneDeep(line);
          await this.$store.dispatch(
            "purchasesDeliveryItems/update",
            purchasesDeliveryItemData
          );
        }
        await this.$store.dispatch(
          "purchasesDeliveries/validate",
          this.purchasesDelivery.id
        );

        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_DELIVERIES.PURCHASES_DELIVERY_VALIDATED"),
        });

        this.$emit("purchasesDeliveryUpdated");
        this.$emit("closePurchasesOrderDeliveryForm");
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {
    showModal() {
      this.setDeliveryItemsModel();
    },
    purchasesDelivery() {
      this.setDeliveryItemsModel();
    },
  },
};
</script>
<style scoped>
.delivery-item .delivery-item-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
