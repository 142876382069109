<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ purchasesDelivery.code }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ purchasesDelivery.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("PURCHASES_DELIVERIES.EXPIRATION_TIME") }}
        </dt>
        <dd class="col-sm-8">
          {{ purchasesDelivery.expiration_time | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="purchasesDelivery.allowedLocations" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="purchasesDelivery.organization" />
        </dd>
      </dl>

      <dl class="row" v-if="purchasesDelivery.purchasesOrder">
        <dt class="col-sm-4">{{ $t("COMMON.PURCHASES_ORDER") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="purchasesDelivery.purchasesOrder" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ISSUER") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="purchasesDelivery.issuer" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
        <dd class="col-sm-8">
          <purchases-delivery-status-badge
            :purchasesDelivery="purchasesDelivery"
          />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4" v-if="purchasesDelivery.excerpt">
          {{ $t("COMMON.NOTE") }}
        </dt>
        <dd class="col-sm-8" v-if="purchasesDelivery.excerpt">
          <div v-html="purchasesDelivery.excerpt"></div>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import PurchasesDeliveryStatusBadge from "./PurchasesDeliveryStatusBadge.vue";

export default {
  name: "purchases-delivery-view-global",

  components: {
    PurchasesDeliveryStatusBadge,
  },

  props: ["purchasesDelivery"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    purchasesDelivery(purchasesDelivery) {},
  },
};
</script>
