<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!purchasesDelivery">
      <span class="loader"></span>
    </span>
    <purchases-delivery-form
      v-if="purchasesDelivery"
      :loading="loading"
      :purchasesDeliveryData="purchasesDelivery"
      :formErrors="formErrors"
      @purchasesDeliveriesubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import PurchasesDeliveryForm from "../partials/PurchasesDeliveryForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { PurchasesDeliveryForm },

  mixins: [alertLeave],

  props: {
    purchasesDeliveryId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      purchasesDelivery: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "purchasesDeliveries/get",
          this.purchasesDeliveryId
        );
        this.purchasesDelivery =
          this.$store.getters["purchasesDeliveries/purchasesDelivery"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(purchasesDelivery) {
      this.loading = true;
      const purchasesDeliveryData = cloneDeep(purchasesDelivery);
      try {
        await this.$store.dispatch(
          "purchasesDeliveries/update",
          purchasesDeliveryData
        );
        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_DELIVERIES.PURCHASES_DELIVERY_UPDATED"),
        });
        const purchasesDelivery = await this.$store.getters[
          "purchasesDeliveries/purchasesDelivery"
        ];
        this.$emit("onViewPurchasesDelivery", purchasesDelivery, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
